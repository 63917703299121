@charset "utf-8";

@import
  "theme/variables",
  "theme/base";

body{
  @include flex(row, center, center);

  height: 100vh;
  max-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  background: white;
  text-align: center;
  line-height: 1.5;
}

.notFoundTitle{
  @include display-font;
  font-size: 15vw;
  position: relative; top: 0; left: 0;
  transform: rotate(0deg);
  animation: .1s linear 0s infinite shakeitbaby;

  @include media-query{
    font-size: 25vw;
  }
}

@keyframes shakeitbaby {
  0%{
    top: -1px; left: 2px;
    transform: rotate(1deg);
  }
  20%{
    top: 3px; left: 0px;
    transform: rotate(0deg);
  }
  40%{
    top: -1px; left: -2px;
    transform: rotate(0.5deg);
  }
  60%{
    top: 0px; left: 3px;
    transform: rotate(-1deg);
  }
  80%{
    top: -3px; left: -1px;
    transform: rotate(1deg);
  }
  100%{
    top: 1px; left: 1px;
    transform: rotate(-0.5deg);
  }
}

.notFoundSubtitle{
  font-size: 1.5rem;
  @include media-query{ font-size: 1.2rem; }
  display: block;
  margin-bottom: 20vh;
}
